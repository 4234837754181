import { AppProps } from "next/app";
import Head from "next/head";

import { GoogleAnalytics } from "@next/third-parties/google";
import { SWRConfig } from "swr";

import { Configuration } from "@/Configuration";
import { AppConfig } from "@/lib/providers/appConfig";
import { GlobalStyles } from "@/lib/providers/globalStyles";

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width,height=device-height" key="viewport" />
      </Head>
      <GlobalStyles />
      <SWRConfig
        value={{
          revalidateIfStale: false,
          revalidateOnFocus: false,
          shouldRetryOnError: false,
        }}>
        <AppConfig>
          <Component {...pageProps} />
          <GoogleAnalytics gaId={Configuration.googleAnalytics.id} />
        </AppConfig>
      </SWRConfig>
    </>
  );
}
