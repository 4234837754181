import { DEV_AGREEMENT_QUESTIONS } from "./assets/data/complianceTraning/development/agreementQuestions";
import { DEV_TWO_CHOICE_QUESTIONS } from "./assets/data/complianceTraning/development/twoChoiceQuestions";
import { PROD_AGREEMENT_QUESTIONS } from "./assets/data/complianceTraning/production/agreementQuestions";
import { PROD_TWO_CHOICE_QUESTIONS } from "./assets/data/complianceTraning/production/twoChoiceQuestions";

export const Configuration = {
  reCaptcha: {
    siteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? "6LcxXUgbAAAAAMlXrtsos8vKZFYWnWjFKINLc7aE",
  },

  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID ?? "ga-dummy",
  },

  api: {
    endpoint: process.env.SSR_API_ENDPOINT ?? process.env.NEXT_PUBLIC_API_ENDPOINT ?? "http://localhost:8080",
  },
  googleForm: {
    endpoint:
      process.env.GOOGLE_FORM_ENDPOINT ??
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSe1xGJ7IdfGeuoKdTNpzljBZn-BkpOi69cCaDcOSKVXwk67ng/formResponse",
  },
  complianceTraining: {
    agreementQuestions: process.env.APP_ENV === "development" ? DEV_AGREEMENT_QUESTIONS : PROD_AGREEMENT_QUESTIONS,
    twoChoiceQuestions: process.env.APP_ENV === "development" ? DEV_TWO_CHOICE_QUESTIONS : PROD_TWO_CHOICE_QUESTIONS,
  },
};
